import moment from 'moment';
import Modal from '../../Modals/Modal/Modal';
import { withLocalize } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import { resetEntireState } from '../../Redux/actions/customPayrollActions';
import { REACT_APP_URL_DELETE_CUSTOM_PAYROLL, REACT_APP_URL_SAVE_CUSTOM_PAYROLL } from '../../components/common/Routes/URLs';
import types from '../../Redux/actions/types';
import ContainerBlue from '../../components/common/Container/ContainerBlue';
import Loader from '../../components/common/Loader/Loader';
import OutcomeBar from '../../components/common/OutcomeBar';
import TempEdgeApi from '../../services/TempEdgeApi';
import TableCustomPayrollConfirm from './InfoCustomPayrollTable/TableCustomPayrollConfirm';
import DropdownList from '../../components/common/Dropdown/DropdownList';

import { generateNumericId } from '../../utils/PayrollUtils';
import { organizeData } from './OrganizeData';
import { normalizeDateSendReports } from '../../utils/ReGexs';
import ActiveLanguageAddTranslation from '../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import addIcon from '../../assets/icons/plus.png';
import Switch from '../../components/common/Switch/Switch';
import { customSuccess, errorOnCustomPayroll, initialCodeHeaders, maxValuesSelected } from '../../components/common/Constants/Constants';
import GrayCommonBtn from '../../components/common/Buttons/GrayCommonBtn';
import BlueCommonBtn from '../../components/common/Buttons/BlueCommonBtn';
import { validateAndStoreErrors } from './ValidatePayrollObjects';
import ApplyToAll from './Applytoall/ApplyToAll';
import TableCustomPayroll from './InfoCustomPayrollTable/TableCustomPayroll';
import { useActiveWeek } from '../../Hooks/useActiveWeek';
import useFindCustomPayrollWeek from '../../Hooks/useFindCustomPayrollWeek';
import CustomPayrollSearch from './CustomPayrollSearch';
import { useFetchFindCustomPayrollCode } from '../../Hooks/useFetchFindCustomPayrollCode';

const CustomPayroll = ({ activeLanguage, history, addTranslationForLanguage, translate }) => {
   const [prevActiveLanguage, setPrevActiveLanguage] = useState(activeLanguage);

   useEffect(() => {
      const hasActiveLanguageChanged = prevActiveLanguage !== activeLanguage;
      if (hasActiveLanguageChanged) {
         history.push(`/custompayroll/${activeLanguage.code}`);
         ActiveLanguageAddTranslation(activeLanguage, addTranslationForLanguage);
         setPrevActiveLanguage(activeLanguage);
      }
   }, [activeLanguage, history, addTranslationForLanguage, prevActiveLanguage]);

   const orgIdFind = JSON.parse(localStorage.getItem('agency'))?.organizationEntity?.orgId;
   const dispatch = useDispatch();

   // Custom Hooks
   const { dataGlobal, loading: loadingCodes, message: messageCodes, getCustomCode } = useFetchFindCustomPayrollCode();
   const { weekActive, loading: loadingCompany, message: messageCompany, selectCompany, errors } = useActiveWeek();

   // Local States
   const [message, setMessage] = useState(null);
   const [modal, setModal] = useState({
      show: false,
      content: null,
      size: null,
   });
   const [selectClient, setSelectClient] = useState(null);
   const [sameData, setSameData] = useState(false);
   const [page, setPage] = useState(1);
   const [selectType, setSelectType] = useState(null);
   const [loading, setLoading] = useState(false);
   const [shift, setShift] = useState('');
   const [errorSelectType, setErrorSelectType] = useState(false);
   const [errorsCustom, setErrorsCustom] = useState([]);
   const [disabledNotCustomList, setDisabledNotCustomList] = useState(false);

   // State and logic for custom payroll headers
   const [headerCustomPayroll, setHeaderCustomPayroll] = useState([]);

   const initializeHeaders = useCallback(() => {
      // Verifica si hay datos en dataGlobal y si selectClient ha sido seleccionado
      if (dataGlobal.length > 0 && selectClient) {
         // Si headerCustomPayroll ya tiene elementos, actualiza los headers nuevos
         if (headerCustomPayroll.length > 0) {
            const existingHeaders = new Set(headerCustomPayroll.map((header) => header.customPayrollTypeId));

            const newHeaders = initialCodeHeaders
               .filter((code) => !existingHeaders.has(code))
               .map((code) => {
                  const header = dataGlobal.find((item) => item.customPayrollTypeId === code);
                  const count = headerCustomPayroll.filter((h) => h.customPayrollTypeId === code).length;

                  return header
                     ? {
                          _idHeader: generateNumericId(),
                          title: `${header.reportTitle} - ${count + 1}`,
                          key: code,
                          customPayrollTypeId: code,
                       }
                     : null;
               })
               .filter(Boolean); // Elimina valores nulos en caso de no encontrar headers

            const updatedHeaders = [...headerCustomPayroll, ...newHeaders];

            // Limitar a un máximo de 6 elementos
            if (updatedHeaders.length > 6) {
               const response = maxValuesSelected;
               setMessage(response);
               setHeaderCustomPayroll(updatedHeaders.slice(0, 6));
            } else {
               setHeaderCustomPayroll(updatedHeaders);
            }
         } else if (headerCustomPayroll.length <= 3) {
            const headers = dataGlobal
               .filter((header) => initialCodeHeaders.includes(header.customPayrollTypeId))
               .map((header) => ({
                  _idHeader: generateNumericId(),
                  title: `${header.reportTitle} - 1`,
                  key: header.customPayrollTypeId,
                  customPayrollTypeId: header.customPayrollTypeId,
               }))
               .sort((a, b) => b.key - a.key);

            setHeaderCustomPayroll([...headers, ...headerCustomPayroll]);
         } else {
            // Si no hay headers aún, inicializa con los headers de dataGlobal
            const headers = dataGlobal
               .filter((header) => initialCodeHeaders.includes(header.customPayrollTypeId))
               .map((header) => ({
                  _idHeader: generateNumericId(),
                  title: `${header.reportTitle} - 1`,
                  key: header.customPayrollTypeId,
                  customPayrollTypeId: header.customPayrollTypeId,
               }))
               .sort((a, b) => b.key - a.key);

            // Limitar a un máximo de 6 elementos
            if (headers.length > 6) {
               const response = maxValuesSelected;
               setMessage(response);
               setHeaderCustomPayroll(headers.slice(0, 6));
            } else {
               setHeaderCustomPayroll(headers);
            }
         }
      }
   }, [dataGlobal, initialCodeHeaders, headerCustomPayroll, setHeaderCustomPayroll, selectClient]);

   // Redux Selectors
   const clientsListRedux = useSelector((state) => state.tempEdge.clientList);
   const clients = useMemo(() => clientsListRedux, [clientsListRedux]);
   const employees = useSelector((state) => state.customPayrollReducer.employeesInCustomPayroll);
   const customPayrollEntityList = useSelector((state) => state.customPayrollReducer.payrollEntityList);
   const deletedItems = useSelector((state) => state.customPayrollReducer.deletedItems);

   // Define resetAll function
   const resetAll = useCallback(() => {
      dispatch(resetEntireState());
      setSelectClient(null);
      setSelectType(null);
      setPage(1);
      setSameData(false);
      setHeaderCustomPayroll([]);
      dispatch({
         type: types.CLEAR_PROP,
         payload: 'paginatorList',
      });
   }, [dispatch]);

   const handleCancel = () => {
      setMessage(null);
      resetAll();
      setHeaderCustomPayroll([]);
   };

   useEffect(() => {
      getCustomCode();
   }, [getCustomCode]);

   useEffect(() => {
      setMessage(null);
      resetAll();
      return () => {
         resetAll();
      };
   }, [resetAll]);

   const handleSelectCompany = useCallback(
      async (value) => {
         dispatch(resetEntireState());
         setMessage(null);
         await selectCompany(value);
         setSelectClient(value);
         setHeaderCustomPayroll([]);
      },
      [selectCompany, resetAll],
   );

   useEffect(() => {
      initializeHeaders();
   }, [dataGlobal, selectClient]);

   const {
      findPayrollWeek,
      loading: loadingFindWeek,
      message: messageFindWeek,
   } = useFindCustomPayrollWeek({
      orgIdFind,
      selectClient,
      headerCustomPayroll,
      dataGlobal,
      employees,
      setHeaderCustomPayroll,
      setShift,
      weekActive,
      initialCodeHeaders,
   });

   useEffect(() => {
      if (messageCodes || messageCompany || messageFindWeek) {
         setMessage(messageCodes || messageCompany || messageFindWeek);
      }
   }, [messageCodes, messageCompany, messageFindWeek]);

   const fieldNames = ['amountPay', 'amountBill', 'hoursPay', 'hoursBill', 'deletePay', 'deleteBill', 'customPayrollId'];

   const handleSubmit = async () => {
      try {
         setLoading(true);

         const customPayrollDelete = customPayrollEntityList
            .filter((item) => {
               const hasNullFields = fieldNames
                  .filter((field) => !['deletePay', 'deleteBill', 'customPayrollId'].includes(field))
                  .every((field) => item[field] === null);

               const markedForDeletion = item.deletePay && item.deleteBill && item.customPayrollId;

               return hasNullFields || markedForDeletion;
            })
            .map(({ customPayrollId }) => ({ customPayrollId }));

         const remainingData = customPayrollEntityList.filter(
            (item) => !customPayrollDelete.some((del) => del.customPayrollId === item.customPayrollId),
         );
         const organizedData = organizeData(remainingData, weekActive);

         // Procesar eliminaciones
         const processDeletions = async (items) => {
            const errorArray = [];
            const deletePromises = items.map((item) =>
               TempEdgeApi.post({
                  url: REACT_APP_URL_DELETE_CUSTOM_PAYROLL,
                  payload: {
                     orgId: orgIdFind,
                     id: item.customPayrollId,
                  },
               }).catch((error) => errorArray.push(error)),
            );

            await Promise.all(deletePromises);
            return errorArray;
         };

         const deletedItemsErrors = await processDeletions(deletedItems);
         const customPayrollDeleteErrors = await processDeletions(customPayrollDelete);

         const allErrors = [...deletedItemsErrors, ...customPayrollDeleteErrors];
         if (allErrors.length > 0) {
            setMessage(allErrors[0]);
            return;
         }

         // Validar si no hay datos para guardar, pero se eliminaron algunos datos
         if (organizedData.length === 0 && (deletedItems.length > 0 || customPayrollDelete.length > 0)) {
            setMessage(customSuccess);
            resetAll();
            return;
         }

         // Si no hay datos para guardar y no se eliminaron datos
         if (organizedData.length === 0) {
            setMessage(new Error('com.tempedge.error.confirmationhours'));
            setDisabledNotCustomList(true);
            return;
         }

         // Validar errores en datos organizados antes de enviar
         const errorValidationCustom = validateAndStoreErrors(organizedData);
         if (errorValidationCustom.length > 0) {
            setMessage(errorOnCustomPayroll);
            return;
         }

         // Enviar datos organizados al endpoint
         const request = {
            orgId: orgIdFind,
            dateList: normalizeDateSendReports(weekActive.end),
            clientId: selectClient.clientId,
            shift,
            customPayrollEntities: organizedData,
         };

         const { data: res } = await TempEdgeApi.post({
            url: REACT_APP_URL_SAVE_CUSTOM_PAYROLL,
            payload: request,
         });

         setMessage(res);
         if (res.status === 200 || res.code === 'TE00') {
            resetAll();
         }
      } catch (error) {
         setMessage(new Error(error));
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      const type = page === 1 ? 'pay' : 'bill';
      if (errorsCustom.length > 0) {
         const errorValidationCustom = validateAndStoreErrors(customPayrollEntityList, type);

         if (errorValidationCustom.length > 0) {
            setErrorsCustom(errorValidationCustom);
            return;
         }

         setErrorsCustom([]);
      }
   }, [customPayrollEntityList]);

   // Handle page change
   const handlePageChange = useCallback(
      (newPage) => {
         const currentType = page === 1 ? 'pay' : 'bill';

         const errorValidationCustom = validateAndStoreErrors(customPayrollEntityList, currentType);

         if (errorValidationCustom.length > 0) {
            setErrorsCustom(errorValidationCustom);
            return;
         }

         setErrorsCustom([]);
         setPage(newPage);
      },
      [customPayrollEntityList, page],
   );

   const handleBackPageChange = useCallback((newPage) => {
      setErrorsCustom([]);
      setPage(newPage);
   }, []);

   // Conditional rendering for loading
   const isLoading = loadingCodes || loadingCompany || loading || loadingFindWeek;

   if (isLoading) {
      return <Loader />;
   }

   const priorityOrder = [51, 17, 27];
   const sortedHeaderCustomPayroll = [...headerCustomPayroll].sort((a, b) => {
      const indexA = priorityOrder.indexOf(a.customPayrollTypeId);
      const indexB = priorityOrder.indexOf(b.customPayrollTypeId);

      if (indexA !== -1 && indexB !== -1) {
         return indexA - indexB;
      } else if (indexA !== -1) {
         return -1;
      } else if (indexB !== -1) {
         return 1;
      } else {
         return a.customPayrollTypeId - b.customPayrollTypeId;
      }
   });

   if (headerCustomPayroll.length === 0 && employees.length > 0) {
      initializeHeaders();
   }

   return (
      <ContainerBlue title="com.tempedge.msg.title.deductionsreimbursements" label={moment(weekActive.end).format('MM-DD-YYYY')}>
         {message && <OutcomeBar response={message} />}
         <>
            {/* PayrollSearch Component */}
            <CustomPayrollSearch
               clients={clients}
               selectClient={selectClient}
               setSelectClient={handleSelectCompany}
               errors={errors}
               selectCompany={handleSelectCompany}
               findPayrollWeek={findPayrollWeek}
               employees={employees}
               setModal={setModal}
               resetAll={() => dispatch(resetEntireState())}
               dataGlobal={dataGlobal}
               initialCodeHeaders={initialCodeHeaders}
               setHeaderCustomPayroll={setHeaderCustomPayroll}
               headerCustomPayroll={headerCustomPayroll}
               setMessage={setMessage}
               weekActive={weekActive}
            />

            {employees.length > 0 && (
               <div className="time-sheet">
                  <div className={`${sameData ? 'mt-4 mx-auto px-2' : 'mx-auto px-2'}`}>
                     <Row className="mt-2">
                        <Col sm={12} md={5} lg={3}>
                           {(() => {
                              let title = '';
                              let listSize = 0;

                              if (page === 1) {
                                 title = translate('com.tempedge.msg.label.paytoemployee');
                                 listSize = employees ? employees.filter((item) => item.labelPay).length : 0;
                              } else if (page === 2) {
                                 title = translate('com.tempedge.msg.label.billtoclient');
                                 listSize = employees ? employees.filter((item) => item.labelBill).length : 0;
                              }

                              return (
                                 <>
                                    <p className="green-text font-weight-bold text-uppercase ">
                                       {`${translate('com.tempedge.msg.menu.custompayroll')} ${title}`}
                                    </p>
                                    {title && (
                                       <p className="tempedge-control-label-semibold">
                                          {translate('com.tempedge.msg.label.listsize')}:<span className="badge">{listSize}</span>
                                       </p>
                                    )}
                                 </>
                              );
                           })()}
                        </Col>
                        <Col sm={12} md={5} lg={3}></Col>
                        <Col sm={12} md={5} lg={3}>
                           {page !== 3 && (
                              <div className="form-group">
                                 <p className="text-left label-p">{translate('com.tempedge.msg.label.addcustompayroll')}</p>
                                 <DropdownList
                                    data={dataGlobal}
                                    textField="reportTitle"
                                    valueField="customPayrollTypeId"
                                    value={selectType}
                                    onChange={(value) => setSelectType(value)}
                                    isInvalid={errorSelectType}
                                 />
                              </div>
                           )}
                        </Col>
                        <Col sm={6} md={2} lg={1} className="margin-botton-payroll">
                           {page !== 3 && (
                              <span
                                 className=" center-block add-fieldArray-btn"
                                 onClick={() => {
                                    if (sortedHeaderCustomPayroll.length <= 5 && selectType) {
                                       const count = sortedHeaderCustomPayroll.filter(
                                          (header) => header.customPayrollTypeId === selectType.customPayrollTypeId,
                                       ).length;
                                       setHeaderCustomPayroll((prev) => [
                                          ...prev,
                                          {
                                             _idHeader: generateNumericId(),
                                             title: `${selectType?.reportTitle || ''} - ${count + 1}`,
                                             key: selectType?.customPayrollTypeId || '',
                                             customPayrollTypeId: selectType?.customPayrollTypeId || '',
                                          },
                                       ]);
                                       setErrorSelectType(false);
                                    } else {
                                       if (sortedHeaderCustomPayroll.length >= 6) {
                                          const response = maxValuesSelected;
                                          setMessage(response);
                                       }
                                       if (!selectType) {
                                          setErrorSelectType(true);
                                       }
                                    }
                                 }}
                              >
                                 <img style={{ width: '40px' }} src={addIcon} alt="addIcon" />
                              </span>
                           )}
                        </Col>
                        <Col sm={6} md={4} lg={2}>
                           {page !== 3 && (
                              <Row className="ml-4">
                                 <div className="form-group ml-auto mr-4">
                                    <p className="tempedge-control-label-semibold">{translate('com.tempedge.msg.label.samehour')}</p>

                                    <Switch
                                       name="week"
                                       checked={sameData}
                                       onChange={(value) => {
                                          setSameData(value);
                                       }}
                                       size="md"
                                    />
                                 </div>
                              </Row>
                           )}
                        </Col>
                     </Row>
                  </div>

                  {sameData && (
                     <ApplyToAll
                        headerCustomPayroll={sortedHeaderCustomPayroll}
                        weekActive={weekActive}
                        type={page === 1 ? 'pay' : 'bill'}
                     />
                  )}

                  {/* Tables based on current page */}
                  {page === 1 && (
                     <TableCustomPayroll
                        headerCustomPayroll={sortedHeaderCustomPayroll}
                        setHeaderCustomPayroll={setHeaderCustomPayroll}
                        weekActive={weekActive}
                        entryType="pay"
                        errorsCustom={errorsCustom}
                        setErrorsCustom={setErrorsCustom}
                     />
                  )}
                  {page === 2 && (
                     <TableCustomPayroll
                        headerCustomPayroll={sortedHeaderCustomPayroll}
                        setHeaderCustomPayroll={setHeaderCustomPayroll}
                        weekActive={weekActive}
                        entryType="bill"
                        errorsCustom={errorsCustom}
                        setErrorsCustom={setErrorsCustom}
                     />
                  )}

                  {page === 3 && (
                     <TableCustomPayrollConfirm
                        headerCustomPayroll={sortedHeaderCustomPayroll}
                        setHeaderCustomPayroll={setHeaderCustomPayroll}
                        weekActive={weekActive}
                     />
                  )}

                  {/* Navigation Buttons */}
                  <div className="row justify-content-center mt-4">
                     <Col sm={6} md={4} lg={4} className="form-group">
                        <GrayCommonBtn tag={'com.tempedge.msg.label.cancel'} onClick={handleCancel} />
                     </Col>

                     {page !== 1 && (
                        <Col sm={6} md={4} lg={4} className="form-group">
                           <GrayCommonBtn
                              tag={page > 1 && 'com.tempedge.msg.label.back'}
                              onClick={() => {
                                 setSameData(false);
                                 handleBackPageChange(page - 1);
                                 setDisabledNotCustomList(false);
                              }}
                           />
                        </Col>
                     )}

                     {/* Botón para cambiar de página */}
                     {page !== 3 && (
                        <Col sm={6} md={4} lg={4} className="form-group">
                           <BlueCommonBtn
                              tag="com.tempedge.msg.label.next"
                              type="button"
                              onClick={() => {
                                 setSameData(false);
                                 handlePageChange(page + 1);
                                 setDisabledNotCustomList(false);
                              }}
                              className="btn btn-primary btn-block register-save-btn save w-100"
                           />
                        </Col>
                     )}

                     {/* Botón para guardar en la página 3 */}
                     {page === 3 && (
                        <Col sm={6} md={4} lg={4} className="form-group">
                           <BlueCommonBtn
                              tag="com.tempedge.msg.label.save"
                              type="button"
                              onClick={handleSubmit}
                              className="btn btn-primary btn-block register-save-btn save w-100"
                              invalid={disabledNotCustomList}
                           />
                        </Col>
                     )}
                  </div>
               </div>
            )}
         </>

         <Modal
            open={modal.show}
            content={modal.content}
            onClose={() => setModal({ show: false, content: null, size: null })}
            modalSize={modal.size ?? 'modal-lg'}
         />
      </ContainerBlue>
   );
};

export default withLocalize(CustomPayroll);
