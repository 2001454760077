import { Field, useFormikContext } from 'formik';
import { Col, Row } from 'react-bootstrap';
import TranslatedLabel from '../../../../components/common/TranslatedLabel/TranslatedLabel';
import TimePicker from '../../../../components/common/TimePicker/TimePicker';
import { convertToMarkup, normalizePhone, onlyDigitsAndDot } from '../../../../utils/ReGexs';

const PositionForm = () => {

   const { values, setFieldValue, errors, touched } = useFormikContext();

   return (
      <div className="client-contents">
         <Row>
            <Col sm={12} md={8} lg={8} className="form-group">
               <Row>
                  <Col sm={12} md={12} lg={12} className="form-group">
                     <TranslatedLabel id="com.tempedge.msg.label.position" />
                     <Field
                        id={`name`}
                        name={`name`}
                        type="text"
                        maxLength={50}
                        className={`tempEdge-input-box form-control ${errors.name && touched.name ? 'has-error' : ''}`}
                        onChange={(e) => setFieldValue('name', e.target.value.replace(/[^a-zA-ZÀ-ú 0-9 .,-/]+/g, '')?.toUpperCase())}
                     />
                  </Col>
                  <Col sm={12} md={6} lg={6} className="form-group ">
                     <TranslatedLabel id="com.tempedge.msg.label.markup" />
                     <Field
                        id="markUp"
                        name="markUp"
                        type="text"
                        maxLength="6"
                        className={`tempEdge-input-box form-control ${errors.markUp && touched.markUp ? 'has-error' : ''}`}
                        onChange={(e) => {
                           const value = e.target.value.replace('%', ''); // Remove '%' if it exists
                           if (!isNaN(value) || value === '') {
                              setFieldValue('markUp', value);
                           }
                        }}
                        value={`${values?.markUp}%`} // Display the value with '%' if not empty
                     />
                  </Col>
                  <Col sm={12} md={6} lg={6} className="form-group ">
                     <TranslatedLabel id="com.tempedge.msg.label.OtMarkup" />
                     <Field
                        id="otMarkUp"
                        name="otMarkUp"
                        type="text"
                        className={`tempEdge-input-box form-control ${errors.otMarkUp && touched.otMarkUp ? 'has-error' : ''}`}
                        onChange={(e) => {
                           const value = e.target.value.replace('%', ''); // Remove '%' if it exists
                           if (!isNaN(value) || value === '') {
                              setFieldValue('otMarkUp', value);
                           }
                        }}
                        value={`${values.otMarkUp}%`} // Display the value with '%' if not empty
                        maxLength="6"
                     />
                  </Col>
               </Row>
            </Col>
            <Col sm={12} md={4} lg={4}>
               <Row>
                  <Col sm={12} md={12} lg={12} className="form-group w-full">
                     <TranslatedLabel id="com.tempedge.msg.label.description" />
                     <Field
                        id={`description`}
                        name={`description`}
                        as="textarea"
                        maxLength="120"
                        className={`text-area-form-department tempEdge-input-box form-control ${
                           errors.description && touched.description ? 'has-error' : ''
                        }`}
                        onChange={(e) =>
                           setFieldValue(
                              'description',
                              (e.target.value = e.target.value.replace(/[^a-zA-ZÀ-ú 0-9.,\-/_$!@#]+/g, ''))?.toUpperCase(),
                           )
                        }
                        value={values.description}
                     />
                  </Col>
               </Row>
            </Col>
         </Row>
         <Row>
            <Col sm={12} md={4} lg={4} className="form-group">
               <TranslatedLabel id="com.tempedge.msg.label.payRate" />
               <Field
                  id={`pay`}
                  name={`pay`}
                  type="text"
                  maxLength="6"
                  className={`tempEdge-input-box form-control ${errors.pay && touched.pay ? 'has-error' : ''}`}
                  onChange={(e) => setFieldValue('pay', (e.target.value = onlyDigitsAndDot(e.target.value)))}
               />
            </Col>
            <Col sm={12} md={4} lg={4} className="form-group">
               <TranslatedLabel id="com.tempedge.msg.label.billRate" />
               <p className="area-client">{'$ ' + (values.pay * convertToMarkup(values?.markUp)).toFixed(2)}</p>
            </Col>
            <Col sm={12} md={4} lg={4} className="form-group">
               <TranslatedLabel id="com.tempedge.msg.label.otBillRate" />
               <p className="area-client">{'$ ' + (values.pay * convertToMarkup(values?.otMarkUp) * 1.5).toFixed(2)}</p>
            </Col>
            <Col sm={12} md={6} lg={6} className="form-group">
               <TranslatedLabel id="com.tempedge.msg.label.regularSchedule" />
               <Field
                  id={`timeIn`}
                  name={`timeIn`}
                  time={values.timeIn}
                  setTime={(e) => setFieldValue('timeIn', e)}
                  type="text"
                  component={TimePicker}
               />
            </Col>
            <Col sm={12} md={6} lg={6} className="form-group mt-3">
               <label className="label-p">&nbsp;</label>
               <Field
                  time={values.timeOut}
                  setTime={(e) => setFieldValue('timeOut', e)}
                  name="timeOut"
                  type="text"
                  component={TimePicker}
               />
            </Col>
            <Col sm={12} md={6} lg={6} className="form-group">
               <TranslatedLabel id="com.tempedge.msg.label.employeeContact" />
               <Field
                  id={`contactName`}
                  name={`contactName`}
                  type="text"
                  className={`tempEdge-input-box form-control ${errors.contactName && touched.contactName ? 'has-error' : ''}`}
                  onChange={(e) => setFieldValue('contactName', e.target.value.replace(/[^a-zA-ZÀ-ú .,-]+/g, ''))}
               />
            </Col>
            <Col sm={12} md={6} lg={6} className="form-group">
               <TranslatedLabel id="com.tempedge.msg.label.contactPhone" />
               <Field
                  id={`contactPhone`}
                  name={`contactPhone`}
                  type="text"
                  className={`tempEdge-input-box form-control ${errors.contactPhone && touched.contactPhone ? 'has-error' : ''}`}
                  onChange={(e) => setFieldValue('contactPhone', (e.target.value = normalizePhone(e.target.value)))}
               />
            </Col>
         </Row>
      </div>
   );
};

export default PositionForm;
