import { isValidNumberWithMax } from '../../utils/ReGexs';

export const validateSingleCustomPayroll = (values) => {
   const errors = {};

   const data = values?.customPayroll || values || [];

   data.forEach((entry, index) => {
      const entryErrors = {};
      const { payCode, billingCode, amountPay, hoursPay, payRate, amountBill, hoursBill, billRate, entryType } = entry;

      if (payCode && payCode.customPayrollTypeId && entryType === 'pay') {
         if (hoursPay && !payRate) {
            entryErrors.payRate = 'Pay Rate is required when Hours Pay is present with Pay code';
         }
         if (amountPay && !payRate) {
            entryErrors.payRate = 'amouint Rate is required when Hours Pay is present with Pay code';
         }

         if (amountPay && !isValidNumberWithMax(amountPay, 4)) {
            entryErrors.amountPay = 'Amount Pay must be a valid number';
         }

         if (hoursPay && !isValidNumberWithMax(hoursPay, 3)) {
            entryErrors.hoursPay = 'Hours Pay must be a valid number';
         }

         if (payRate && !isValidNumberWithMax(payRate, 3)) {
            entryErrors.payRate = 'Pay Rate must be a valid number';
         }
      }

      if (billingCode && billingCode.customPayrollTypeId && entryType === 'bill') {
         if (hoursBill && !billRate) {
            entryErrors.billRate = 'Bill Rate is required when Hours Bill is present with Billing code';
         }
         if (amountBill && !billRate) {
            entryErrors.billRate = 'Bill Rate is required when Hours Bill is present with Billing code';
         }

         if (amountBill && !isValidNumberWithMax(amountBill, 4)) {
            entryErrors.amountBill = 'Amount Bill must be a valid number';
         }

         if (hoursBill && !isValidNumberWithMax(hoursBill, 3)) {
            entryErrors.hoursBill = 'Hours Bill must be a valid number';
         }

         if (billRate && !isValidNumberWithMax(billRate, 3)) {
            entryErrors.billRate = 'Bill Rate must be a valid number';
         }
      }

      if ((amountPay || hoursPay) && (!payCode || !payCode.customPayrollTypeId)) {
         entryErrors.payCode = 'Pay code is required when Amount Pay or Hours Pay or Pay Rate is present';
      }

      if ((amountBill || hoursBill) && (!billingCode || !billingCode.customPayrollTypeId)) {
         entryErrors.billingCode = 'Billing code is required when Amount Bill or Hours Bill or Bill Rate is present';
      }

      // Add entryErrors to the errors array if there are any errors for this entry
      if (Object.keys(entryErrors).length > 0) {
         if (!errors.customPayroll) {
            errors.customPayroll = [];
         }
         errors.customPayroll[index] = entryErrors;
      }
   });

   return errors;
};
