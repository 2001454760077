import payrollCycle from '../PayrollCicle';
import { Col, Row } from 'react-bootstrap';
import { Field, useFormikContext } from 'formik';
import TranslatedLabel from '../../../../components/common/TranslatedLabel/TranslatedLabel';
import DropdownList from '../../../../components/common/Dropdown/DropdownList';
import { Translate } from 'react-localize-redux';
import { onlyDigitsAndDot, onlyNumbersandDecimals } from '../../../../utils/ReGexs';

const ClientFirstPage = ({ salesman, editClient = false, stateList }) => {
   const { setFieldValue, values, errors, touched } = useFormikContext();

   return (
      <>
         <div className="new-client-header">
            <h2>
               {!editClient ? (
                  <Translate id="com.tempedge.client.title.createclient"></Translate>
               ) : (
                  <Translate id="com.tempedge.client.title.updateclient"></Translate>
               )}
            </h2>
         </div>
         <div className="new-clients-contents">
            <div className="client-contents">
               <Row>
                  <Col sm={12} md={4} lg={4} className="form-group">
                     <TranslatedLabel id="com.tempedge.msg.label.company" />
                     <Field
                        id="company"
                        name="company"
                        type="text"
                        className={` tempEdge-input-box form-control ${errors.company && touched.company ? 'has-error' : ''}`}
                        maxLength="50"
                        onChange={(e) =>
                           setFieldValue(
                              'company',
                              (e.target.value = e.target.value.replace(/[^a-zA-ZÀ-ú 0-9 .,-/_]+/g, '')?.toUpperCase()),
                           )
                        }
                     />
                  </Col>
               </Row>
               <Row>
                  {/* salesman */}
                  <Col sm={12} md={4} lg={4} className="form-group mt-3">
                     <TranslatedLabel id="com.tempedge.msg.label.salesman" />
                     <Field
                        id="salesman"
                        name="salesman"
                        className={` tempEdge-input-box form-control ${errors.salesman && touched.salesman ? 'has-error' : ''}`}
                        data={salesman}
                        valueField="personId"
                        value={{ personId: values.salesman }}
                        textField="salesmanName"
                        component={DropdownList}
                        onChange={(value) => setFieldValue('salesman', value.personId)}
                        isInvalid={errors.salesman && touched.salesman}
                     />
                  </Col>
                  {/* payrollcicle */}
                  <Col sm={12} md={4} lg={4} className="form-group mt-3">
                     <TranslatedLabel id="com.tempedge.msg.label.payrollCycle" />
                     <Field
                        name="payrollCycle"
                        data={payrollCycle}
                        valueField="payrollId"
                        textField="payrollCycle"
                        value={{ payrollId: values.payrollCycle }}
                        className={` tempEdge-input-box form-control ${errors.payrollCycle && touched.payrollCycle ? 'has-error' : ''}`}
                        component={DropdownList}
                        onChange={(value) => setFieldValue('payrollCycle', value.payrollId)}
                        isInvalid={errors.payrollCycle && touched.payrollCycle}
                     />
                  </Col>
                  {/* company initials */}
                  <Col sm={12} md={4} lg={4} className="form-group mt-3">
                     <TranslatedLabel id="com.tempedge.msg.label.companyInitials" />
                     <Field
                        id="companyInitials"
                        name="companyInitials"
                        type="text"
                        className={` tempEdge-input-box form-control ${errors.companyInitials && touched.companyInitials ? 'has-error' : ''}`}
                        maxLength="20"
                        onChange={(e) =>
                           setFieldValue(
                              'companyInitials',
                              (e.target.value = e.target.value.replace(/[^a-zA-ZÀ-ú 0-9 .,-/_]+/g, '')?.toUpperCase()),
                           )
                        }
                     />
                  </Col>
                  {/* wordCompCode */}
                  <Col sm={12} md={4} lg={4} className="form-group mt-3">
                     <TranslatedLabel id="com.tempedge.msg.label.workCompCode" />
                     <Field
                        id="workCompCode"
                        name="workCompCode"
                        type="text"
                        maxLength="6"
                        className={` tempEdge-input-box form-control ${errors.workCompCode && touched.workCompCode ? 'has-error' : ''}`}
                        onChange={(e) =>
                           setFieldValue('workCompCode', (e.target.value = e.target.value.replace(onlyNumbersandDecimals, '')))
                        }
                     />
                  </Col>
                  {/* wordCompRate */}
                  <Col sm={12} md={4} lg={4} className="form-group mt-3">
                     <TranslatedLabel id="com.tempedge.msg.label.workCompRate" />
                     <Field
                        id="workCompRate"
                        name="workCompRate"
                        type="text"
                        maxLength="6"
                        className={` tempEdge-input-box form-control ${errors.workCompRate && touched.workCompRate ? 'has-error' : ''}`}
                        onChange={(e) =>
                           setFieldValue('workCompRate', (e.target.value = e.target.value.replace(onlyNumbersandDecimals, '')))
                        }
                     />
                  </Col>
                  {/* WordState*/}
                  <Col sm={12} md={4} lg={4} className="form-group mt-3">
                     <TranslatedLabel id="com.tempedge.msg.label.workstate" />
                     <Field
                        name="workRegion"
                        data={stateList}
                        valueField="regionId"
                        textField="shortCode"
                        value={{ regionId: values.workRegion  }}
                        className={` tempEdge-input-box form-control ${errors.workRegion && touched.workRegion ? 'has-error' : ''}`}
                        component={DropdownList}
                        limit={2}
                        onChange={(value) => {
                           setFieldValue('workRegion', value.regionId);
                        }}
                        isInvalid={errors.workRegion && touched.workRegion}
                     />
                  </Col>
               </Row>
               <Row>
                  {/* parte antes del coments */}
                  <Col sm={12} md={8} lg={8}>
                     <Row>
                        {/* attnto */}
                        <Col sm={12} md={6} lg={6} className="form-group mt-3">
                           <TranslatedLabel id="com.tempedge.msg.label.attnTo" />
                           <Field
                              id="attnTo"
                              name="attnTo"
                              type="text"
                              className={` tempEdge-input-box form-control ${errors.attnTo && touched.attnTo ? 'has-error' : ''}`}
                              maxLength="30"
                              onChange={(e) =>
                                 setFieldValue(
                                    'attnTo',
                                    (e.target.value = e.target.value.replace(/[^a-zA-ZÀ-ú 0-9 .,-/_]+/g, '')?.toUpperCase()),
                                 )
                              }
                           />
                        </Col>
                        {/* email */}
                        <Col sm={12} md={6} lg={6} className="form-group mt-3">
                           <TranslatedLabel id="com.tempedge.msg.label.email" />
                           <Field
                              id="email"
                              name="email"
                              type="email"
                              className={` tempEdge-input-box form-control ${errors.email && touched.email ? 'has-error' : ''}`}
                              onChange={(e) => setFieldValue('email', (e.target.value = e.target.value?.toUpperCase()))}
                           />
                        </Col>
                        {/* markupClient */}
                        <Col sm={12} md={6} lg={6} className="form-group mt-3">
                           <TranslatedLabel id="com.tempedge.msg.label.markupClient" />
                           <Field
                              id="markupClient"
                              name="markupClient"
                              type="text"
                              className={` tempEdge-input-box form-control ${errors.markupClient && touched.markupClient ? 'has-error' : ''}`}
                              maxLength="5"
                              onChange={(e) => setFieldValue('markupClient', (e.target.value = onlyDigitsAndDot(e.target?.value)))}
                           />
                        </Col>
                        {/* OtMarkupClient */}
                        <Col sm={12} md={6} lg={6} className="form-group mt-3">
                           <TranslatedLabel id="com.tempedge.msg.label.otMarkupClient" />
                           <Field
                              id="otMarkupClient"
                              name="otMarkupClient"
                              className={` tempEdge-input-box form-control ${errors.otMarkupClient && touched.otMarkupClient ? 'has-error' : ''
                                 }`}
                              maxLength="5"
                              onChange={(e) => setFieldValue('otMarkupClient', (e.target.value = onlyDigitsAndDot(e.target?.value)))}
                           />
                        </Col>
                     </Row>
                  </Col>
                  {/*  coments */}
                  <Col sm={12} md={4} lg={4}>
                     <Row>
                        <Col md={12} className="form-group mt-3">
                           <TranslatedLabel id="com.tempedge.msg.label.comments" />
                           <Field
                              id="comments"
                              name="comments"
                              as="textarea"
                              maxLength="100"
                              className="text-area-form tempEdge-input-box form-control"
                              onChange={(e) =>
                                 setFieldValue(
                                    'comments',
                                    (e.target.value = e.target.value.replace(/[^a-zA-ZÀ-ú 0-9 .,-/_]+/g, '')?.toUpperCase()),
                                 )
                              }
                           />
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </div>
         </div>
      </>
   );
};

export default ClientFirstPage;
