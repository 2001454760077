import { useEffect, useState } from 'react';
import '../../../assets/styles/components/Clients.css';
import Stepper from 'react-stepper-horizontal';
import addIcon from '../../../assets/icons/plus.png';
import TempEdgeApi from '../../../services/TempEdgeApi';
import {
   REACT_APP_URL_CLIENT_DELETE_POSITION,
   REACT_APP_URL_CLIENT_FIND_BY_ID,
   REACT_APP_URL_CLIENT_LIST,
   REACT_APP_URL_CLIENT_SAVE,
   REACT_APP_URL_CLIENT_UPDATE,
   REACT_APP_URL_COMMONS_FILE_DELETE,
   REACT_APP_URL_COMMONS_FILE_UPDATE,
   REACT_APP_URL_DELETE_CLIENT_DEPARTMENT,
   REACT_APP_URL_PERSON_SALESMAN_LIST,
} from '../../../components/common/Routes/URLs';
import ActiveLanguageAddTranslation from '../../../components/common/ActiveLanguageAddTranslation/ActiveLanguageAddTranslation';
import { Form, Formik } from 'formik';
import OutcomeBar from '../../../components/common/OutcomeBar';
import ClientFirstPage from './StepFormCLient/ClientFirstPage';
import ClientSecondPage from './StepFormCLient/ClientSecondPage';
import ClientThirdPage from './StepFormCLient/ClientThirdPage';
import ClientFourthPage from './StepFormCLient/ClientFourthPage';
import { Translate, withLocalize } from 'react-localize-redux';
import Loader from '../../../components/common/Loader/Loader';
import { Col, Row } from 'react-bootstrap';
import { buildClientRequest, initialValues, validationSchemaClient } from './validationSchemaClient';
import StepNavigation from './StepNavigation';
import Container from '../../../components/common/Container/Container';
import ModalSimple from '../../../Modals/ModalSimple/ModalSimple';
import DepartmentList from './DepartmentList/DepartmentList';

import useFetchCountryList from '../../../Hooks/useFetchCountryList';
import { DEFAULT_COUNTRY } from '../../../components/common/Constants/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { getListClientAll } from '../../../Redux/actions/tempEdgeActions';
import NewDepartmentandPositions from './DepartmentsAndPositions/NewDepartmentandPositions';
import EditDepartmentandPositions from './DepartmentsAndPositions/EditDepartmentandPositions';
import GreenCommonBtn from '../../../components/common/Buttons/GreenCommonBtn';
import { generateUniqueId } from '../../../utils/PayrollUtils';

/**
 * Represents the CreateOrUpdateClient component.
 * This component is responsible for rendering a form to create or update a client.
 *
 * @component
 * @returns {JSX.Element} The CreateOrUpdateClient component
 */

const CreateOrUpdateClient = (props) => {
   const dispatch = useDispatch();
   const clientIdUpdate = useSelector((state) => state.tempEdge.clientId);
   const orgIdClient = JSON.parse(localStorage.getItem('agency'))?.organizationEntity.orgId;
   const [personId, setPersonId] = useState();
   const [initialValuesCLient, setInitialValuesCLient] = useState(initialValues);
   const [salesman, setSalesman] = useState([]);
   const [message, setMessage] = useState('');
   const [customMessage, setCustomMessage] = useState('');
   const [loading, setLoading] = useState(false);
   const [page, setPage] = useState(1);
   const [editClient, setEditClient] = useState(false);
   const [showModal, setShowModal] = useState(false);
   const [countryList, setCountryList] = useState([]);
   const [stateList, setStateList] = useState([]);
   const [departmentEditList, setDepartmentEditList] = useState([]);
   const [deletePositionList, setDeletePositionList] = useState([]);
   const [deleteDepartmentList, setDeleteDepartmentList] = useState([]);
   const [deleteFileList, setDeleteFileList] = useState([]);
   const countryData = useFetchCountryList();
   const [fileorcodefix, setFileorcodefix] = useState(0);
   const [newDepartment, setNewDepartment] = useState(true);
   const [prevActiveLanguage, setPrevActiveLanguage] = useState(props.activeLanguage);

   useEffect(() => {
      const hasActiveLanguageChanged = prevActiveLanguage !== props.activeLanguage;
      if (hasActiveLanguageChanged) {
         if (editClient) {
            props.history.push(`/client/update/${props.activeLanguage.code}`);
         } else {
            props.history.push(`/client/new/${props.activeLanguage.code}`);
         }
         ActiveLanguageAddTranslation(props.activeLanguage, props.addTranslationForLanguage);
         setPrevActiveLanguage(props.activeLanguage);
      }
   }, [props.activeLanguage, props.history, props.addTranslationForLanguage, prevActiveLanguage, editClient]);

   // Fetch the country list and set the country list and state list
   useEffect(() => {
      let isMounted = true;
      async function fetchData() {
         if (countryData.length > 0 && isMounted) {
            setCountryList(countryData);
            const country = countryData.filter((country) => {
               return country.countryId === DEFAULT_COUNTRY;
            })[0];
            setStateList(country.regionEntityList);
         }
      }
      fetchData();
      return () => {
         isMounted = false;
      };
   }, [countryData]);

   // Fetch the salesman list
   useEffect(() => {
      let isMounted = true;
      async function fetchData() {
         try {
            setLoading(true);
            const { data: response } = await TempEdgeApi.post({
               url: REACT_APP_URL_PERSON_SALESMAN_LIST,
               payload: {
                  orgId: orgIdClient,
                  page: 0,
               },
            });

            if (isMounted) {
               if (response.status === 200 && response.code === 'TE00') {
                  const { content } = response.result;
                  setSalesman(
                     content?.map((salesman) => {
                        return {
                           ...salesman,
                           salesmanName: salesman.firstName + ' ' + salesman.lastName,
                        };
                     }),
                  );
               }
            }
         } catch (error) {
            setMessage('Error fetching data');
         } finally {
            setLoading(false);
         }
      }
      fetchData();
      return () => {
         isMounted = false;
      };
   }, [orgIdClient]);

   // Fetch the client data to update
   useEffect(() => {
      const path = props.location.pathname.split('/');
      if (path[2] === 'update' && typeof clientIdUpdate !== 'undefined') {
         setEditClient(true);
         const fetchClient = async () => {
            const { data: response } = await TempEdgeApi.post({
               url: REACT_APP_URL_CLIENT_FIND_BY_ID,
               payload: {
                  orgId: orgIdClient,
                  id: clientIdUpdate,
               },
            });
            if (response.status === 200 && response.code === 'TE00') {
               const client = response.result;
               setPersonId(client.contact.personId);
               setInitialValuesCLient({
                  ...client,
                  company: client.clientName,
                  attnTo: client.attn,
                  companyInitials: client.clientInitials,
                  clientaddress: client.address,
                  clientaddress2: client.address2,
                  clientcity: client.city,
                  clientcountry: client.country,
                  clientstate: client.region,
                  clientzipcode: client.zipcode,
                  clientcontactcellphone: client.contact.cellPhone,
                  clientcontactphone: client.contact.phone,
                  clientfirstName: client.contact.firstName,
                  clientlastName: client.contact.lastName,
                  comments: client.notes,
                  email: client.email,
                  markupClient: client.commonMarkup,
                  otMarkupClient: client.commonOtMarkup,
                  payrollCycle: client.payrollSchedule,
                  phone: client.phone,
                  workCompCode: client.wcCode,
                  workCompRate: client.wcRate,
                  workRegion: client.workRegion,
                  departments: client.departments.map((department) => {
                     return {
                        _id: generateUniqueId(),
                        departmentId: department.departmentId,
                        name: department.name,
                        positions: department.positions.map((position) => ({
                           ...position,
                           _id: generateUniqueId(),
                        })),
                     };
                  }),
                  salesman: client.clientSellers[0]?.person?.personId,
                  fileUpdate: client.fileEntities,
               });
            } else {
               setMessage(response);
               setPage(1);
            }
         };
         fetchClient();
      }
      return () => {
         setEditClient(false);
      };
   }, [props.clientId, props.location.pathname, orgIdClient, clientIdUpdate]);

   const saveNewClient = async (values, actions) => {
      const request = buildClientRequest(values, orgIdClient);
      setLoading(true);

      try {
         const { data } = await TempEdgeApi.post({
            url: REACT_APP_URL_CLIENT_SAVE,
            payload: request,
         });

         if (data.status === 200 && data.code === 'TE00') {
            const clientId = data?.result?.clientId;
            setCustomMessage('');

            if (clientId && values.fileUpdate.length > 0) {
               await handleFileUploads(values.fileUpdate, clientId, orgIdClient);
            }

            if (fileorcodefix > 0) {
               setMessage({
                  status: 200,
                  code: 'TE-E08',
                  message: 'com.tempedge.warn.clientsaveandfileerror',
               });
            } else {
               setMessage(data);
               actions.resetForm();
               setPage(1);
               setInitialValuesCLient(initialValues);
            }
         } else {
            handleErrorResponse(data, values);
         }
      } catch (error) {
         handleErrorResponse(error, values);
      } finally {
         await updateClientList();
         setLoading(false);
      }
   };

   // Manejar la carga de archivos
   const handleFileUploads = async (fileUpdate, clientId, orgId) => {
      for (const file of fileUpdate) {
         const fileData = {
            orgId,
            moduleId: clientId,
            module: 'com.tempedge.msg.menu.client',
            extension: file.fileName.split('.').pop(),
            type: file.type,
         };

         const formData = new FormData();
         formData.append('data', new Blob([JSON.stringify(fileData)], { type: 'application/json' }));
         formData.append('file', file.file);

         try {
            const res = await TempEdgeApi.postMultipart({
               url: REACT_APP_URL_COMMONS_FILE_UPDATE,
               payload: formData,
            });

            if (res.data?.code !== 'TE00') {
               setFileorcodefix((prev) => prev + 1);
            }
         } catch {
            setFileorcodefix((prev) => prev + 1);
         }
      }
   };

   const handleErrorResponse = (errorData, values) => {
      setCustomMessage('');
      setMessage(errorData);
      setPage(1);
      setInitialValuesCLient(values);
   };

   // llamado a la api de client list para cargar en el redux la lista de clientes actualizada
   const updateClientList = async () => {
      const { data: companies } = await TempEdgeApi.post({
         url: REACT_APP_URL_CLIENT_LIST,
         payload: {
            orgId: JSON.parse(localStorage.getItem('agency'))?.organizationEntity?.orgId,
         },
      });

      if (companies.status === 200 && companies.code === 'TE00') {
         dispatch(getListClientAll(companies.result));
      }
   };

   // Update the client
   const updateClient = async (values, actions) => {
      // Asignar personId directamente
      values.contact.personId = personId;

      // Construir la solicitud con campos adicionales
      const additionalFields = { clientId: clientIdUpdate };
      const request = buildClientRequest(values, orgIdClient, additionalFields);

      setLoading(true);

      try {
         const { data } = await TempEdgeApi.post({
            url: REACT_APP_URL_CLIENT_UPDATE,
            payload: request,
         });

         if (data.code !== 'TE00') {
            handleErrorResponse(data, values);
            return;
         }

         // Manejar eliminaciones y actualizaciones
         await Promise.all([
            handleDeletions(deleteDepartmentList, REACT_APP_URL_DELETE_CLIENT_DEPARTMENT, {
               orgId: orgIdClient,
               key: 'departmentId',
            }),
            handleDeletions(deletePositionList, REACT_APP_URL_CLIENT_DELETE_POSITION, {
               orgId: orgIdClient,
               key: 'id',
            }),
            handleFileDeletions(deleteFileList, REACT_APP_URL_COMMONS_FILE_DELETE, clientIdUpdate),
            handleFileUploads(values.fileUpdate, clientIdUpdate, orgIdClient),
         ]);

         // Verificar si hubo errores en las operaciones anteriores
         if (fileorcodefix > 0) {
            setMessage({
               status: 200,
               code: 'TE-E08',
               message: 'com.tempedge.warn.clientsaveandfileerror',
            });
            setPage(1);
            setInitialValuesCLient(values);
         } else {
            setPage(1);
            props.history.push('/client/list/en');
         }
      } catch (error) {
         handleErrorResponse(error, values);
      } finally {
         setLoading(false);
      }
   };

   // Función genérica para manejar eliminaciones
   const handleDeletions = async (items, url, payloadConfig) => {
      for (const item of items) {
         if (item !== undefined) {
            const payload = {
               orgId: payloadConfig.orgId,
               [payloadConfig.key]: item,
            };
            try {
               const res = await TempEdgeApi.post({ url, payload });
               if (res.data?.code !== 'TE00') {
                  setFileorcodefix((prev) => prev + 1);
               }
            } catch {
               setFileorcodefix((prev) => prev + 1);
            }
         }
      }
   };

   // Función para manejar eliminaciones de archivos
   const handleFileDeletions = async (fileList, url, clientId) => {
      for (const file of fileList) {
         if (typeof file?.file === 'string') {
            const payload = {
               moduleId: clientId,
               module: 'com.tempedge.msg.menu.client',
               type: file.type,
            };
            try {
               const res = await TempEdgeApi.post({ url, payload });
               if (res.data?.code !== 'TE00') {
                  setFileorcodefix((prev) => prev + 1);
               }
            } catch {
               setFileorcodefix((prev) => prev + 1);
            }
         }
      }
   };

   const onCloseModal = () => {
      setShowModal(false);
   };

   const steps = [{ title: '' }, { title: '' }, { title: '' }, { title: '' }];

   if (loading) return <Loader />;

   return (
      <Formik
         initialValues={initialValuesCLient}
         enableReinitialize={true}
         onSubmit={async (values, actions) => {
            if (values.departments.length === 0) {
               actions.setFieldError('departments', 'com.tempedge.error.client.clientincompleted');
               setCustomMessage('com.tempedge.error.client.clientincompleted');
               return;
            }
            if (!editClient) {
               saveNewClient(values, actions);
            } else {
               updateClient(values, actions);
            }
         }}
         validationSchema={validationSchemaClient(page)}
      >
         {({ isSubmitting, resetForm, setFieldValue, values, errors }) => {
            const handleCancel = () => {
               if (editClient) {
                  props.history.push('/client/list/en');
               }
               setPage(1);
               resetForm();
               setCustomMessage('');
               setMessage('');
            };

            // Add a department to the list of departments the modal form
            const addDepartment = (department) => {
               const newListDepartments = [...values.departments, department];
               setFieldValue('departments', newListDepartments);
               onCloseModal();
            };

            // Update a department in the list of departments the modal form
            const updateDepartment = (department) => {
               const newListDepartments = [...values.departments];

               const index = newListDepartments.findIndex((dep) => dep._id === department._id);

               if (index !== -1) {
                  newListDepartments[index] = department;
                  setFieldValue('departments', newListDepartments);
                  onCloseModal();
               }
            };

            // Edit a department in the list of departments the modal form
            const handleEditClient = (index) => {
               setDepartmentEditList(values.departments[index]);
               setShowModal(true);
               setNewDepartment(false);
            };

            // Delete a department in the list of departments the modal form
            const handleDeleteClient = (index, departmentId) => {
               const newDepartments = values.departments.filter((department, i) => i !== index);
               if (editClient) {
                  setDeleteDepartmentList([...deleteDepartmentList, departmentId]);
               }
               setFieldValue('departments', newDepartments);
               setDepartmentEditList([]);
            };

            // Add a new department to the list of departments
            const addNewDepartment = () => {
               setDepartmentEditList([]);
               setNewDepartment(true);
               setShowModal(true);
            };

            return (
               <>
                  <Stepper
                     steps={steps}
                     activeStep={page - 1}
                     activeColor="#eb8d34"
                     completeColor="#8cb544"
                     defaultBarColor="#eb8d34"
                     completeBarColor="#8cb544"
                     barStyle="solid"
                     circleFontSize={16}
                  />
                  <Container
                     width="85%"
                     btns={
                        <StepNavigation page={page} setPage={setPage} isSubmitting={isSubmitting} steps={steps} onCancel={handleCancel} />
                     }
                     title={`com.tempedge.client.title.${editClient ? 'updateclient' : 'createclient'}`}
                  >
                     <Form className="mb-5 mx-auto wizard-wrapper -z-20">
                        {message && <OutcomeBar response={message} />}
                        {customMessage && <OutcomeBar personMessage={customMessage} />}
                        <Row>
                           <Col sm={12} md={8} lg={8} className="client-col">
                              {page === 1 && <ClientFirstPage salesman={salesman} editClient={editClient} stateList={stateList} />}
                              {page === 2 && (
                                 <ClientSecondPage
                                    editClient={editClient}
                                    countryList={countryList}
                                    stateList={stateList}
                                    setStateList={setStateList}
                                 />
                              )}
                              {page === 3 && <ClientThirdPage editClient={editClient} />}
                              {page === 4 && <ClientFourthPage editClient={editClient} setDeleteFileList={setDeleteFileList} />}
                           </Col>
                           <Col sm={12} md={4} lg={4} className="dept-col">
                              <div className="department-list">
                                 <div className="department-list-header">
                                    <h2>
                                       <Translate id="com.tempedge.msg.label.departmentlist" />
                                    </h2>
                                 </div>
                                 <div className="department-list-contents">
                                    {values && values?.departments?.length > 0 ? (
                                       <>
                                          <DepartmentList
                                             departments={values.departments}
                                             onEdit={handleEditClient}
                                             onDelete={handleDeleteClient}
                                             setShowModal={setShowModal}
                                             departmentEditList={departmentEditList}
                                          />
                                          <span
                                             style={{ marginTop: '2.2rem' }}
                                             className="float-button center-block pull-right add-fieldArray-btn"
                                             onClick={() => addNewDepartment()}
                                          >
                                             <img src={addIcon} alt="addIcon" />
                                          </span>
                                       </>
                                    ) : (
                                       <div className='text-center mx-auto'>
                                          <GreenCommonBtn
                                             type="button"
                                             onClick={() => addNewDepartment()}
                                             tag="com.tempedge.msg.label.adddepartment"
                                             class={`${errors.departments ? 'has-error-btn has-error' : ''}`}
                                          />
                                       </div>
                                    )}
                                 </div>
                              </div>
                           </Col>
                        </Row>
                        <ModalSimple
                           content={
                              newDepartment ? (
                                 <NewDepartmentandPositions onCloseModal={onCloseModal} addDepartment={addDepartment} values={values}/>
                              ) : (
                                 <EditDepartmentandPositions
                                    onCloseModal={onCloseModal}
                                    updateDepartment={updateDepartment}
                                    listDepartment={departmentEditList}
                                    setDeletePositionList={setDeletePositionList}
                                    values={values}
                                 />
                              )
                           }
                           open={showModal}
                           client={true}
                           onClose={() => onCloseModal()}
                        />
                     </Form>
                  </Container>
               </>
            );
         }}
      </Formik>
   );
};

export default withLocalize(CreateOrUpdateClient);
