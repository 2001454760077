import { Translate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { titleDeptPos } from '../../../components/common/Constants/Constants';

const PayrollSection = ({ title, titleRH, hours, titleAMT, amount }) => (
   <div className="payroll-section">
      <div className="title-confir-custom-payroll">
         <span>{title}</span>
      </div>

      <div className="form-group-payroll">
         <p className="custom-totals">{`${titleRH} ${hours} - ${titleAMT} ${amount}`}</p>
      </div>
   </div>
);

const TableCustomPayrollConfirm = ({ headerCustomPayroll, setHeaderCustomPayroll, weekActive }) => {
   const employees = useSelector((state) => {
      return state.customPayrollReducer.employeesInCustomPayroll;
   });

   const titleRH = 'RH : ';
   const titleAMT = 'AMT : $';

   const customPayrollEntityList = useSelector((state) => state.customPayrollReducer.payrollEntityList);

   // Calculate totals per header
   const totalsPerHeader = headerCustomPayroll.map((label) => {
      let totalAmountPay = 0;
      let totalHoursPay = 0;
      let totalAmountBill = 0;
      let totalHoursBill = 0;

      customPayrollEntityList.forEach((entry) => {
         if (
            (entry.billingCode?.customPayrollTypeId === label.customPayrollTypeId ||
               entry.payCode?.customPayrollTypeId === label.customPayrollTypeId) &&
            entry._idHeader === label._idHeader
         ) {
            totalAmountPay += parseFloat(entry.amountPay) || 0;
            totalAmountBill += parseFloat(entry.amountBill) || 0;
            totalHoursPay += parseFloat(entry.hoursPay) || 0;
            totalHoursBill += parseFloat(entry.hoursBill) || 0;
         }
      });

      return {
         labelId: label._idHeader,
         totalAmountPay: totalAmountPay + totalAmountBill,
         totalHoursPay: totalHoursPay + totalHoursBill,
      };
   });

   return (
      <table className="responsive-table mt-4">
         <thead>
            <tr>
               <th scope="col" className="text-center table-header-left-first">
                  <Translate id="com.tempedge.msg.label.name" />
               </th>
               <th scope="col" className="text-center table-header-mid-first">
                  {titleDeptPos}
               </th>
               {headerCustomPayroll?.map((label, index) => {
                  return (
                     <th
                        scope="col"
                        key={index}
                        className="text-center day-header table-header-mid-first"
                        data-initial={label?.title.charAt(0).toUpperCase()}
                     >
                        <span>{label?.title}</span>
                     </th>
                  );
               })}
               <th scope="col" className="text-center table-header-right-first">
                  <Translate id="com.tempedge.msg.label.total" />
               </th>
            </tr>
         </thead>
         <tbody>
            {employees.map((payroll, index) => {
               const rowId = `${payroll?.person?.personId}-${index}`;
               const shouldRenderRow = payroll.deletePay && payroll.deleteBill;

               // Calcular los totales para la fila actual
               let totalHoursPayRow = 0;
               let totalAmountPayRow = 0;
               let totalHoursBillRow = 0;
               let totalAmountBillRow = 0;

               headerCustomPayroll.forEach((label) => {
                  // Filtrar las entradas relacionadas con el payroll actual y el header actual
                  const relatedEntries = customPayrollEntityList.filter(
                     (entry) => entry.id === payroll.id && entry._idHeader === label._idHeader,
                  );

                  // Sumar los valores de hoursPay, amountPay, hoursBill y amountBill
                  relatedEntries.forEach((entry) => {
                     totalHoursPayRow += parseFloat(entry.hoursPay) || 0;
                     totalAmountPayRow += parseFloat(entry.amountPay) || 0;
                     totalHoursBillRow += parseFloat(entry.hoursBill) || 0;
                     totalAmountBillRow += parseFloat(entry.amountBill) || 0;
                  });
               });

               return (
                  !shouldRenderRow && (
                     <tr key={rowId} className={'time-sheet-row'}>
                        <th scope="row">{payroll?.person?.fullName || payroll.firstName + ' ' + payroll.lastName}</th>
                        <td data-title={`${titleDeptPos}`}>
                           <p className="subtitle-payroll">
                              {payroll.department ? `${payroll.department.name} - ${payroll.position.name}` : payroll.position?.name}
                           </p>
                        </td>
                        {headerCustomPayroll?.map((label, idx) => {
                           // Encontrar las entradas relacionadas con el payroll actual y el header actual
                           const relatedEntries = customPayrollEntityList.filter(
                              (entry) => entry.id === payroll.id && entry._idHeader === label._idHeader,
                           );

                           // Obtener el valor de hoursPay y amountPay de las entradas relacionadas
                           const hoursEntry = relatedEntries.find((entry) => entry.hoursPay);
                           const amountEntry = relatedEntries.find((entry) => entry.amountPay);
                           const hoursBillEntry = relatedEntries.find((entry) => entry.hoursBill);
                           const amountBillEntry = relatedEntries.find((entry) => entry.amountBill);

                           const hoursPay = hoursEntry ? parseFloat(hoursEntry.hoursPay).toFixed(2) : '0.00';
                           const amountPay = amountEntry ? parseFloat(amountEntry.amountPay).toFixed(2) : '0.00';
                           const hoursBill = hoursBillEntry ? parseFloat(hoursBillEntry.hoursBill).toFixed(2) : '0.00';
                           const amountBill = amountBillEntry ? parseFloat(amountBillEntry.amountBill).toFixed(2) : '0.00';

                           return (
                              <td key={idx} className="text-center mx-auto">
                                 <div className="form-group-payroll">
                                    <PayrollSection
                                       title="To Pay :"
                                       titleRH={titleRH}
                                       hours={hoursPay}
                                       titleAMT={titleAMT}
                                       amount={amountPay}
                                    />
                                 </div>
                                 <div className="form-group-payroll">
                                    <PayrollSection
                                       title="To Bill :"
                                       titleRH={titleRH}
                                       hours={hoursBill}
                                       titleAMT={titleAMT}
                                       amount={amountBill}
                                    />
                                 </div>
                              </td>
                           );
                        })}

                        {/* Columna de Totales de la fila */}
                        <td className="text-center">
                           <div className="form-group-payroll">
                              <PayrollSection
                                 title="To Pay :"
                                 titleRH={titleRH}
                                 hours={totalHoursPayRow.toFixed(2)}
                                 titleAMT={titleAMT}
                                 amount={totalAmountPayRow.toFixed(2)}
                              />
                           </div>
                           <div className="form-group-payroll">
                              <PayrollSection
                                 title="To Bill :"
                                 titleRH={titleRH}
                                 hours={totalHoursBillRow.toFixed(2)}
                                 titleAMT={titleAMT}
                                 amount={totalAmountBillRow.toFixed(2)}
                              />
                           </div>
                        </td>
                     </tr>
                  )
               );
            })}
            {/* Totals Row */}
            <tr className="time-sheet-row-responsive">
               <th colSpan={2} className="text-uppercase text-right custom-totals total-colspan">
                  <Translate id="com.tempedge.msg.label.total" />
               </th>
               {headerCustomPayroll.map((label, index) => {
                  const totals = totalsPerHeader.find((t) => t.labelId === label._idHeader);
                  const totalHoursPay = totals ? totals?.totalHoursPay?.toFixed(2) : '0.00';
                  const totalAmountPay = totals ? totals?.totalAmountPay?.toFixed(2) : '0.00';

                  return (
                     <td key={index} className="text-center total-colspan">
                        <p className="custom-totals">
                           {titleRH} {totalHoursPay} - {titleAMT} {totalAmountPay}
                        </p>
                     </td>
                  );
               })}
               <td className="text-center total-colspan">
                  <p className="custom-totals">
                     {titleRH} {totalsPerHeader.reduce((sum, totals) => sum + totals.totalHoursPay, 0).toFixed(2)} - {titleAMT}
                     {totalsPerHeader.reduce((sum, totals) => sum + totals.totalAmountPay, 0).toFixed(2)}
                  </p>
               </td>
            </tr>
         </tbody>
      </table>
   );
};

export default TableCustomPayrollConfirm;
