import { customPayrollTypes } from '../types/customPayrollTypes';

const initialState = {
   employeesInCustomPayroll: [],
   payrollEntityList: [],
   deletedItems: [],
};

export const customPayrollReducer = (state = initialState, action) => {
   switch (action.type) {
      case customPayrollTypes.ADD_EMPLOYEE_IN_CUSTOMPAYROLL: {
         const updatedEmployees = [...state.employeesInCustomPayroll, action.payload];
      
         updatedEmployees.sort((a, b) => {
            const lastNameComparison = a.lastName.localeCompare(b.lastName);
            if (lastNameComparison !== 0) {
               return lastNameComparison;
            }
            return a.firstName.localeCompare(b.firstName);
         });
      
         return {
            ...state,
            employeesInCustomPayroll: updatedEmployees,
         };
      }

      case customPayrollTypes.ADD_CUSTOM_PAYROLL: {
         const updatedPayrollList = state.payrollEntityList.map((payroll) => ({
            ...payroll,
            ...action.payload,
         }));
         return {
            ...state,
            payrollEntityList: updatedPayrollList,
         };
      }

      case customPayrollTypes.ADD_CUSTOM_PAYROLL_LIST: {
         const newEntries = action.payload;
         return {
            ...state,
            payrollEntityList: [...state.payrollEntityList, ...newEntries],
         };
      }

      case customPayrollTypes.ADD_SAME_DATA_CUSTOM_PAYROLL: {
         const updatedPayrollEntityList = state.employeesInCustomPayroll.map((employee) => {
            const { personId } = employee;
            const updatedPayload = {
               ...action.payload,
               id: personId,
               person: { personId },
            };
            return updatedPayload;
         });
         const employeesInCustomPayrollSame = state.employeesInCustomPayroll.map((employee) => {
            const customSame = state.payrollEntityList.filter((item) => item.id !== employee.personId);
            return {
               ...employee,
               confirmation: customSame.length >= 1,
            };
         });

         return {
            ...state,
            payrollEntityList: [...state.payrollEntityList, ...updatedPayrollEntityList],
            employeesInCustomPayroll: employeesInCustomPayrollSame,
         };
      }

      case customPayrollTypes.DELETE_CUSTOM_PAYROLL: {
         const idsToDelete = action.payload.ids.map(Number); 
      
         const updatedEmployeesInCustomPayroll = state.employeesInCustomPayroll.filter((employee) => {
            const shouldDelete = idsToDelete.includes(Number(employee.personId));
            return !shouldDelete; 
         });
      
         const newListCustomPayroll = state.payrollEntityList.filter((item) => {
            const shouldDelete = idsToDelete.includes(Number(item.personId));            
            return !shouldDelete;
         });
      
         const deletedItems = state.payrollEntityList.filter((item) => {
            const shouldDelete = idsToDelete.includes(Number(item.personId));
            return shouldDelete; 
         });
      
         return {
            ...state,
            employeesInCustomPayroll: updatedEmployeesInCustomPayroll,
            payrollEntityList: newListCustomPayroll,
            deletedItems: [...state.deletedItems, ...deletedItems], 
         };
      }

      case customPayrollTypes.REMOVE_EMPLOYEE_IN_CUSTOMPAYROLL: {
         const { _id, label} = action.payload;

         return {
            ...state,
            employeesInCustomPayroll: state.employeesInCustomPayroll.map((employee) => {
               if (employee.id === _id) {
                  const update = {
                     ...employee                     
                  };
                  if (label === 'labelBill') {
                     update.deleteBill = true;
                  } else if (label === 'labelPay') {
                     update.deletePay = true;
                  }
                  return update;
               }

               return employee;
            }),
            payrollEntityList: state.payrollEntityList.map((employee) => {
               if (employee.id === _id) {
                  const update = {
                     ...employee,
                  };

                  if (label === 'labelBill') {
                     update.amountBill = null;
                     update.hoursBill = null;
                     update.billingCode = null;
                     update.deleteBill = true;
                  } else if (label === 'labelPay') {
                     update.amountPay = null;
                     update.hoursPay = null;
                     update.payCode = null;
                     update.deletePay = true;
                  }

                  return {
                     ...employee,
                     ...update,
                  };
               }
               return employee;
            }),
         };
      }

      case customPayrollTypes.UPDATE_CUSTOM_PAYROLL_ENTRY: {
         const updatedEntry = action.payload;
         const existingEntryIndex = state.payrollEntityList.findIndex(
            (entry) => entry.id === updatedEntry.id && entry._idHeader === updatedEntry._idHeader
         );

         let updatedPayrollEntityList;

         if (existingEntryIndex !== -1) {
            const existingEntry = state.payrollEntityList[existingEntryIndex];
            const mergedEntry = {
               ...existingEntry,
               ...updatedEntry,
            };
            updatedPayrollEntityList = [
               ...state.payrollEntityList.slice(0, existingEntryIndex),
               mergedEntry,
               ...state.payrollEntityList.slice(existingEntryIndex + 1),
            ];
         } else {
            updatedPayrollEntityList = [...state.payrollEntityList, updatedEntry];
         }

         return {
            ...state,
            payrollEntityList: updatedPayrollEntityList,
         };
      }

      case customPayrollTypes.ADD_CUSTOM_PAYROLL_FOR_ALL_EMPLOYEES: {
         const entries = action.payload;

         const updatedPayrollEntityList = [...state.payrollEntityList];

         entries.forEach((customPayroll) => {
            state.employeesInCustomPayroll.forEach((employee) => {
               const payRate = employee?.rate || 0; 
               const billRate =employee?.bill || employee?.rate || 0; 

               const newEntry = {
                  id: employee.id,
                  personId: employee.personId,
                  employeeId: employee.employeeId,
                  _idHeader: customPayroll._idHeader,
                  payRate,
                  billRate,
                  ...customPayroll,
                  department: employee.department,
                  position: employee.position,
               };

               const existingIndex = updatedPayrollEntityList.findIndex(
                  (item) => item.id === employee.id && item._idHeader === customPayroll._idHeader,
               );

               if (existingIndex !== -1) {
                  updatedPayrollEntityList[existingIndex] = {
                     ...updatedPayrollEntityList[existingIndex],
                     payRate, 
                     billRate,
                     department: employee.department,
                     position: employee.position,
                     ...customPayroll,
                  };
               } else {
                  updatedPayrollEntityList.push(newEntry);
               }
            });
         });

         return {
            ...state,
            payrollEntityList: updatedPayrollEntityList,
         };
      }

      case customPayrollTypes.DELETE_HEADER_AND_RELATED_PAYROLL_ENTRIES: {
         const { _idHeader } = action.payload;
         // Filtrar `payrollEntityList` para eliminar entradas con `_idHeader` correspondiente
         const updatedPayrollEntityList = state.payrollEntityList.filter((item) => item._idHeader !== _idHeader);

         // Filtrar `deletedItems` para almacenar los eliminados con el `_idHeader` correspondiente
         const itemsToDelete = state.payrollEntityList.map((item) => {
            if (item._idHeader === _idHeader) return item;
            else return null;
         });

         const filteredItemsToDelete = itemsToDelete.filter((item) => item !== null);

         return {
            ...state,
            payrollEntityList: updatedPayrollEntityList,
            deletedItems: [...state.deletedItems, ...filteredItemsToDelete],
         };
      }

      case customPayrollTypes.RESET_ENTIRE_STATE: {
         return initialState;
      }

      default:
         return state;
   }
};
