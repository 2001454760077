import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../Modals/Modal/Modal";
import {
  addPayrollValidation,
  changeStates,
  requestDayValidation,
} from "../../Redux/actions/payrollValidationActions";
import { tempedgeAPI } from "../../Redux/actions/tempEdgeActions";
import {
  addEmployeeInTimeEntryTable,
  addEmployeeWeekInTimeEntryTable,
  changeState,
  clearProp,
  requestDay,
  setFindByWeek,
  weekActive,
} from "../../Redux/actions/timeEntryActions";
import { timeEntryTypes } from "../../Redux/types/timeEntryTypes";
import "../../assets/styles/components/Datepicker.css";
import DepartmentTables from "../../components/common/DepartmentTables/DepartmentTables";
import DropdownList from "../../components/common/Dropdown/DropdownList";
import Loader from "../../components/common/Loader/Loader";
import InfoModal from "../../components/common/Modal/InfoModal";
import {
  REACT_APP_URL_PAYROLL_FIND_BY_DAY,
  REACT_APP_URL_PAYROLL_FIND_BY_WEEK,
  REACT_APP_URL_PAYROLL_FIND_WEEK_ACTIVE,
} from "../../components/common/Routes/URLs";
import Switch from "../../components/common/Switch/Switch";
import TempEdgeApi from "../../services/TempEdgeApi";
import {
  getWeekActiveDates,
  normalizeDate,
  normalizeDateSend,
} from "../../utils/ReGexs";


const SearchForm = ({ translate, tempEdge, validator, setReportConfg,clearValues }) => {
  const props = useSelector((store) => store.timeEntryReducer);
  const { shift, department, weekday, client, customDate, position } = props;
 
  const today = moment(new Date());
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [viewDepartmentsButtonDisabled, setViewDepartmentsButtonDisabled] =
    useState(true);
  const [departments, setDepartments] = useState([]);
  const [positions, setPositions] = useState([]);
  const [isAllDepartments, setIsAllDepartments] = useState(false);
  const [toggleSwitchChecked, setToggleSwitchChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requeridWeek, setRequeridWeek] = useState(false);
  const [content, setContent] = useState("");
  const [modalSize, setModalSize] = useState("");
  const [dates, setDates] = useState(customDate);
  const [weekDates, setWeekDates] = useState({
    thisWeekInit: today.clone().startOf("isoWeek").format("YYYY-MM-DD"),
    thisWeekEnd: today.clone().endOf("isoWeek").format("YYYY-MM-DD"),
    lastWeekInit: today
      .clone()
      .subtract(1, "week")
      .startOf("isoWeek")
      .format("YYYY-MM-DD"),
    lastWeekEnd: today
      .clone()
      .subtract(1, "week")
      .endOf("isoWeek")
      .format("YYYY-MM-DD"),
  });

  // this is a constructor of the department and position in a single line for each payroll
  function mapDepartmentAndPositionList(
    departments = [],
    isPositionNameComposed
  ) {
    const positionAndDepartmentList = [];
    departments.forEach((department) => {
      if (department.positions) {
        department.positions.forEach((position) => {
          positionAndDepartmentList.push({
            name: isPositionNameComposed
              ? `${department.name} - ${position.name}`
              : position.name,
            positionId: position.positionId,
            pay: position.pay,
            departmentId: department.departmentId,
            departmentName: department.name,
            positionName: position.name,
          });
        });
      }
    });
    return positionAndDepartmentList;
  }

  // this function is to locally change the position that the search engine selects,
  // department, adding an object with the department, but also change a redux state
  function handleDepartmentChange({ key, value }) {
    let positions = value.positions;
    if (value.positions) {
      setPositions(positions);
    }
  
    setIsAllDepartments(value.departmentId === null || !value.departmentId);    
    dispatch(changeState({ key: "position", value: "All" }));
    dispatch(changeState({ key, value }));
  }
	
  const clientsListRedux = useSelector(state => {
    return state.tempEdge.clientList;
	});
	
	const clientList = useMemo(() => clientsListRedux, [clientsListRedux])  

  const weekActiveChange = useCallback(async (client, date) => {
    const { data: weekActive } = await TempEdgeApi.post({
      url: REACT_APP_URL_PAYROLL_FIND_WEEK_ACTIVE,
      payload: {
        orgId: JSON.parse(localStorage.getItem("agency"))?.organizationEntity
          ?.orgId,
        clientId: client,
        dateList: date,
      },
    });
    const {
      status = "",
      result: { startDate = "", endDate = "" },
    } = weekActive;

    const { data: lastWeekActive } = await TempEdgeApi.post({
      url: REACT_APP_URL_PAYROLL_FIND_WEEK_ACTIVE,
      payload: {
        orgId: JSON.parse(localStorage.getItem("agency"))?.organizationEntity
          ?.orgId,
        clientId: client,
        dateList: moment(weekActive.result.startDate)
          .subtract(1, "d")
          .format("YYYY-MM-DD"),
      },
    });
    const {
      status: lastStatus = "",
      result: { startDate: lastStartDate = "", endDate: lastEndDate = "" },
    } = lastWeekActive;

    if (lastStatus && status === 200) {
      setWeekDates({
        thisWeekInit: startDate,
        thisWeekEnd:endDate,
        lastWeekInit:lastStartDate,
        lastWeekEnd:lastEndDate,
      });
    }
  }, []);
  // function that is activated when the client changes, to load the data of that client
  // I make a validation so that there is only one All, if it already exists it does not add more, if not, it adds it.
  // the constructor of the mapdeártamentandposition function is used and it is passed department and positions
  const onChangeClientList = useCallback(
    async (clientSelected) => {
      const { departments, reportByFile } = clientSelected;
        setReportConfg(reportByFile);
      departments?.unshift({ departmentId: null, name: "All" });
      if (departments?.length > 0) {
        if (departments[1]?.name === "All")
          departments?.shift({ departmentId: null, name: "All" });
      }
      const positionAndDepartmentList = mapDepartmentAndPositionList(
        departments,
        true
      );
      const positions = mapDepartmentAndPositionList(departments);
      dispatch(changeState({ key: "client", value: clientSelected }));
      dispatch(
        changeState({ key: "positionList", value: positionAndDepartmentList })
      );
      if (departments) {
        setDepartments(departments);
        setPositions(positions);
        setViewDepartmentsButtonDisabled(false);
      }
      if (isAllDepartments) {
        setDepartments(departments);
        setPositions(positions);
        setViewDepartmentsButtonDisabled(false);
      }
      await weekActiveChange(
        clientSelected.clientId,
        moment(new Date()).format("YYYY-MM-DD")
      );
    },
    [dispatch, isAllDepartments, setReportConfg, weekActiveChange]
  );

  useEffect(() => {    
    dispatch(changeState({ key: "customDate", value: dates }));
  }, [dispatch, onChangeClientList, tempEdge, dates]);


  useEffect(()=>{
    if (clientList.length === 1 ) {
      onChangeClientList(clientList[0]);
    }else if(client === "" && clientList.length === 1){
      onChangeClientList(clientList[0]);
    }
  },[clientList,onChangeClientList,client])

  // This function is executed to search the data by day, week or last week, the request is created
  const findPayrollList = () => {
    
    const orgId = JSON.parse(localStorage.getItem("agency"))?.organizationEntity
      ?.orgId;
    const date = normalizeDateSend(new Date());
    if(validator){
      clearValues();
    }
    // this is a validation to make the request request to data
    if (weekday !== "") {
      dispatch(setFindByWeek(null));
      setRequeridWeek(false);
      const request = {
        orgId,
        clientId: client?.clientId ? String(client?.clientId) : "",
        shift: shift?.shiftId ? String(shift.shiftId) : "",
        departmentId: department?.departmentId ? String(department?.departmentId) : "",
        positionId: position?.positionId ? String(position?.positionId) : "",
        parameterKey: validator ? "com.tempedge.msg.menu.payroll": "com.tempedge.msg.menu.timeentry",
        dateList: date,
      };

      const clearStates = () => {
        dispatch(changeStates({ key: "payrollWeekValidation", value: [] }));
        dispatch(changeStates({ key: "absentValidation", value: [] }));
        dispatch(changeStates({ key: "absentEmployeesWeek", value: [] }));
        dispatch(
          changeStates({ key: "payrollResultWeekValidation", value: [] })
        );
      };
      if (weekday?.value === "THISWEEK") {
        request.startDate = weekDates.thisWeekInit;
        request.endDate = weekDates.thisWeekEnd;
      }
      if (weekday?.value === "LASTWEEK") {
        request.startDate = weekDates.lastWeekInit;
        request.endDate = weekDates.lastWeekEnd;
      }

      if (weekday.value === "CUSTOM") {
        clearStates();
        if (toggleSwitchChecked === true) {
          findByWeek(request, customDate);
          dispatch(setFindByWeek(customDate));
          dispatch(clearProp());
        }
        if (validator) {
          findByWeek(request, date);
          dispatch(setFindByWeek(date));
          dispatch(clearProp());
        } else if (!toggleSwitchChecked && !validator) {
          findByDay(request);
          dispatch(clearProp());
          dispatch(setFindByWeek(null));
        }
      } else {
        clearStates();
        findByWeek(request, date);
        setToggleSwitchChecked(false);
        dispatch(clearProp());
      }
      dispatch(changeState({ key: "resultBarMessage", value: null }));
    } else {
      dispatch(
        changeState({
          key: "resultBarMessage",
          value: new Error("com.tempedge.error.fieldsrequired"),
        })
      );
    }
    if (weekday === "") {
      setRequeridWeek(true);
    }
    if (weekday !== "") {
      setRequeridWeek(false);
    }
    // once the search is done, it deletes what is in the redux, leaves it blank waiting for the new answer
  };

  const findByDay = async (request) => {
    request.dateList = moment(customDate).format("YYYY-MM-DD");
    dispatch(requestDay(request));
    const requestWeekActive = {
      orgId: request.orgId,
      clientId: request.clientId,
      dateList: request.dateList,
    };
    try {
      setLoading(true);
      const response = await dispatch(
        tempedgeAPI(
          REACT_APP_URL_PAYROLL_FIND_BY_DAY,
          request,
          timeEntryTypes.FIND_PAYROLL
        )
      );
      if (response.data.code === "TE00") {
        if (response.data.result.length > 0) {
          const weekActive = await dispatch(
            tempedgeAPI(
              REACT_APP_URL_PAYROLL_FIND_WEEK_ACTIVE,
              requestWeekActive,
              timeEntryTypes.FIND_WEEK_ACTIVE
            )
          );
          if (weekActive?.data.result) {
            activeAction(weekActive.data.result);
          }
          addEmployee(response.data.result);
        } else {
          dispatch(
            changeState({
              key: "resultBarMessage",
              value: new Error("com.tempedge.msg.label.norecordsfound"),
            })
          );
        }
      } else {
        dispatch(
          changeState({
            key: "resultBarMessage",
            value: new Error("com.tempedge.msg.label.norecordsfound"),
          })
        );
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(
        changeState({
          key: "resultBarMessage",
          value: {
            translateId: "com.tempedge.error.undefine",
            type: "error",
          },
        })
      );
    }
  };

  // se utiliza para buscar la data de la semana
  const findByWeek = async (request, date) => {
    if (customDate && customDate !== null) {
      const { startDate, endDate } = await getWeekActiveDates(
        client?.clientId,
        normalizeDateSend(customDate)
      );
      request.startDate = startDate;
      request.endDate = endDate;
      request.dateList = normalizeDateSend(customDate);
    }
    dispatch(setFindByWeek(date));
    const requestWeekActive = {
      orgId: request.orgId,
      clientId: request.clientId,
      dateList: request.startDate,
    };
    dispatch(requestDay(request));
    dispatch(setFindByWeek(date));
    if (validator) dispatch(requestDayValidation(request));
    
    try {
      setLoading(true);
      const response = await dispatch(
        tempedgeAPI(
          REACT_APP_URL_PAYROLL_FIND_BY_WEEK,
          request,
          timeEntryTypes.FIND_PAYROLL
        )
        );
      if (response.data.code === "TE00") {
        const data1 = request.startDate;
        const data2 = request.endDate;
        if (response.data.result.length > 0) {
          const weekActive = await dispatch(
            tempedgeAPI(
              REACT_APP_URL_PAYROLL_FIND_WEEK_ACTIVE,
              requestWeekActive,
              timeEntryTypes.FIND_WEEK_ACTIVE
            )
          );
          if (weekActive?.data.result) {
            activeAction(weekActive.data.result);
          }
          if (validator) {
            addValidation(response.data.result, data1, data2);
            // setDates(null);
          } else {
            addEmployeeWeek(response.data.result, data1, data2);
          }
        } else {
          dispatch(
            changeState({
              key: "resultBarMessage",
              value: response.data,
            })
          );
        }
      } else {
        dispatch(
          changeState({
            key: "resultBarMessage",
            value: response.data,
          })
        );
      }
      setLoading(false);
    } catch (error) {
      
      dispatch(
        changeState({
          key: "resultBarMessage",
          value: new Error("com.tempedge.error.undefine"),
        })
      );
    }finally{
      setLoading(false);
    }
  };

  const activeAction = (result) => {
    dispatch(weekActive(result.active));
    if (!result?.active) {
      dispatch(
        changeState({
          key: "resultBarMessage",
          value: new Error("com.tempedge.error.weeknotactive"),
        })
      );
    }
  };

  // function that validates if you want to search for a specific date,
  // also if you want to search all the data for that week, the switch changes state
  const renderDateAndToggleFields = () => {
    return (
      <>
        {!validator ? (
          <div className="form-check col-lg-3 col-12">
            <p className="text-left label-p">
              {translate("com.tempedge.msg.label.findbyweek")}
            </p>
            <Switch
              name="week"
              checked={toggleSwitchChecked}
              onChange={(value) => setToggleSwitchChecked(value)}
            />
          </div>
        ) : department.name !== "All" ? (
          <div className="form-check col-lg-3 col-12"></div>
        ) : null}
        <div className="form-group col-lg-3 col-12">
          <p className="text-left label-p">
            {translate("com.tempedge.msg.label.date")}
          </p>
          <DatePicker
            onChange={(date) => setDates(date)}
            selected={dates}
            value={dates}
            className="form-control tempEdge-input-box"
            showMonthDropdown
            showYearDropdown
            maxDate={null}
            placeholderText={"mm/dd/yyyy"}
          />
        </div>
      </>
    );
  };

  // Set Modal visible or not
  const toggleDeparmentListModal = (client) => {
    if (client) {
      onChangeClientList(client);
    }
    const filteredClient = {
      ...client,
      departments: client.departments.filter(department => department.name !== "All")
    };
    const departmentTables = <DepartmentTables clientSelected={filteredClient} />;
    toggleModal(departmentTables, "modal-md");
  };

  const toggleModal = (contents, modalSizes) => {
    setShowModal(!showModal);
    setContent(contents);
    setModalSize(modalSizes);
  };

  // function that is run when searching by day and obtaining the result, the data is passed to the redux
  const addEmployee = (employees = []) => {
    employees.map((employee) =>{
      const totalRegHour = employee.totalRegHour + employee.totalOtHour;
      return dispatch(
        addEmployeeInTimeEntryTable({
          payrollId : employee.payrollId,
          dateList: employee.dateList,
          personId: employee.person.personId,
          employeeId: employee.person.employeeId,
          lastName: employee.person.lastName,
          firstName: employee.person.firstName,
          lunch: employee.lunch,
          lockPayroll: employee.lockPayroll,
          payrollState: employee.payrollState,
          position: {
            positionName: employee?.position?.name,
            positionId: employee?.position?.positionId,
            pay: employee?.position?.pay,
            departmentId: employee?.department?.departmentId,
            departmentName: employee?.department?.name,
          },
          office: {
            officeId: employee.office.officeId,
          },
          timeIn: employee.timeIn,
          timeOut: employee.timeOut,
          attendance: employee.attendance,
          totalOtHour: employee.totalOtHour,
          totalRegHour: totalRegHour && totalRegHour?.toFixed(2),
          payRate: employee.payRate,
          billRate: employee.billRate,
          otBillRate: employee.otBillRate,
          shift: employee.shift
        })
      )
    }
    );
  };

  // function that is run when searching by week, the data is passed to the redux
  const addEmployeeWeek = (payroll, date1, date2) => {
    const employees = [...payroll];
    employees.sort((a, b) => {
      if (a.person.fullName > b.person.fullName) {
        return 1;
      }
      if (a.person.fullName < b.person.fullName) {
        return -1;
      }
      return 0;
    });
    dispatch(addEmployeeWeekInTimeEntryTable({ employees, date1, date2 }));
  };

  // Función para generar un ID único
function generarID() {
  return '_' + Math.random().toString(36).substr(2, 9);
}
  // function that is run when searching by week, the data is passed to the redux for validation
  const addValidation = (data, date1, date2) => {
  
    const employees = data.map(obj => {
      return { id: generarID(), ...obj };
    });;
    employees.sort((a, b) => {
      if (a.person.fullName > b.person.fullName) {
        return 1;
      }
      if (a.person.fullName < b.person.fullName) {
        return -1;
      }
      return 0;
    });
    dispatch(addPayrollValidation({ employees, date1, date2 }));
  };

  const getCompany = () => {
    let companyField;
  
    // Constante filteredClient para reutilizarla
    const filteredClient = clientList.length === 1 ? {
      ...clientList[0],
      departments: clientList[0].departments.filter(department => department.name !== "All")
    } : client;
  
    if (clientList.length === 1) {
      companyField = (
        <InfoModal
          value={filteredClient.clientName} // Pasar el cliente filtrado al modal
          isModal={true}
          onClick={() => toggleDeparmentListModal(filteredClient)}
        />
      );
    } else {
      companyField = (
        <div className="input-group">
          <DropdownList
            className="form-control"
            value={client}
            data={clientList}
            valueField="clientId"
            textField="clientName"
            selectGroup
            defaultValue={null}
            onChange={(clientSelected) => onChangeClientList(clientSelected)}
          />
          <div className="input-group-append">
            <button
              type="button"
              onClick={() => toggleDeparmentListModal(filteredClient)}
              title={translate(
                "com.tempedge.msg.label.viewdepartmentandposotionlist"
              )}
              className="btn btn-green"
              disabled={viewDepartmentsButtonDisabled}
            >
              &#128712;
            </button>
          </div>
        </div>
      );
    }
    return companyField;
  };

  const shiftList = [
    { shiftName: translate("com.tempedge.msg.label.all"), shiftId: null },
    { shiftName: translate("com.tempedge.msg.label.1st"), shiftId: 1 },
    { shiftName: translate("com.tempedge.msg.label.2nd"), shiftId: 2 },
    { shiftName: translate("com.tempedge.msg.label.3rd"), shiftId: 3 },
  ];

  const weekdayOptions = client
    ? [
        {
          label: `FROM ${normalizeDate(weekDates.lastWeekInit)} TO ${normalizeDate(weekDates.lastWeekEnd)}`,
          value: "LASTWEEK",
        },
        {
          label: `FROM ${normalizeDate(weekDates.thisWeekInit)} TO ${normalizeDate(weekDates.thisWeekEnd)}`,
          value: "THISWEEK",
        },
        { label: translate("com.tempedge.msg.label.custom"), value: "CUSTOM" },
      ]
    : [];

  if (loading) return <Loader />;
  return (
    <>
      <div className="row mt-2">
        <div className="form-group col-12 col-lg-3">
          <p className="text-left label-p">
            {translate("com.tempedge.msg.label.company")}
          </p>
          {getCompany()}
        </div>
        <div className="form-group col-12 col-lg-3">
          <p className="text-left label-p">
            {translate("com.tempedge.msg.label.orgdepartment")}
          </p>
          <DropdownList
            className="form-control"
            value={department}
            data={departments}
            valueField="departmentId"
            textField={"name"}
            onChange={(departmentSelected) =>
              handleDepartmentChange({
                key: "department",
                value: departmentSelected,
              })
            }
          />
        </div>
        {positions.length > 0 &&
          department.name !== "All" &&
          !isAllDepartments &&
          department && (
            <div className="form-group col-12 col-lg-3">
              <p className="text-left label-p">
                {translate("com.tempedge.msg.label.position")}
              </p>
              <DropdownList
                className="form-control"
                value={position}
                data={positions}
                valueField="positionId"
                textField="name"
                defaultValue={null}
                onChange={(positionSelected) =>
                  dispatch(
                    changeState({ key: "position", value: positionSelected })
                  )
                }
              />
            </div>
          )}

        <div className="form-group col-12 col-lg-3">
          <p className="text-left label-p">
            {translate("com.tempedge.msg.label.shift")}
          </p>
          <DropdownList
            value={shift}
            data={shiftList}
            valueField="shiftId"
            textField="shiftName"
            onChange={(shiftSelected) =>
              dispatch(
                changeState({ key: "shift", value: shiftSelected })
              )
              
            }
          />
        </div>
      </div>

      <div className="row ">
        <div className="form-group col-12 col-lg-3">
          <p className="text-left label-p">
            {translate("com.tempedge.msg.label.selectweek")}
          </p>
          <DropdownList
            value={weekday}
            isInvalid={requeridWeek}
            data={weekdayOptions}
            valueField="value"
            textField="label"
            onChange={(weekdaySelected) => {
              dispatch(changeState({ key: "weekday", value: weekdaySelected }));
              setDates(null);
            }}
          />
        </div>

        {weekday.value === "CUSTOM" && renderDateAndToggleFields()}
        <div className="form-group col-12 col-lg-3">
          <button
            type="button"
            onClick={() => findPayrollList()}
            className="btn btn-blue w-100 "
            style={{ marginTop: "2.8rem" }}
          >
            {translate("com.tempedge.msg.label.find")}
          </button>
        </div>
        <Modal
          content={content}
          open={showModal}
          onClose={() => toggleModal()}
          modalSize={modalSize}
        />
      </div>
    </>
  );
};

export default SearchForm;
