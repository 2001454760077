import { isValidNumberWithMax } from '../../utils/ReGexs';

export const validateAndStoreErrors = (values, entryType) => {
   const errorsArray = [];

   values.forEach((entry) => {
      const { payCode, billingCode, amountPay, hoursPay, payRate, amountBill, hoursBill, billRate, id, _idHeader } = entry;
      const entryErrors = {};

      if (payCode && payCode.customPayrollTypeId && entryType === 'pay') {
         if (hoursPay && !payRate) {
            entryErrors.hours = 'hours';
            entryErrors.type = 'pay';
            entryErrors.id = id;
            entryErrors._idHeader = _idHeader;
         }
         if (amountPay && !payRate) {
            entryErrors.amount = 'amount';
            entryErrors.type = 'pay';
            entryErrors.id = id;
            entryErrors._idHeader = _idHeader;
         }
         if (amountPay && !isValidNumberWithMax(amountPay, 4)) {
            entryErrors.amount = 'amount';
            entryErrors.type = 'pay';
            entryErrors.id = id;
            entryErrors._idHeader = _idHeader;
         }
         if (hoursPay && !isValidNumberWithMax(hoursPay, 3)) {
            entryErrors.hours = 'hours';
            entryErrors.type = 'pay';
            entryErrors.id = id;
            entryErrors._idHeader = _idHeader;
         }
         if (payRate && !isValidNumberWithMax(payRate, 3)) {
            entryErrors.hours = 'hours';
            entryErrors.amount = 'amount';
            entryErrors.type = 'pay';
            entryErrors.id = id;
            entryErrors._idHeader = _idHeader;
         }
      }

      if (billingCode && billingCode.customPayrollTypeId && entryType === 'bill') {
         if (hoursBill && !billRate) {
            entryErrors.hours = 'hours';
            entryErrors.type = 'bill';
            entryErrors.id = id;
            entryErrors._idHeader = _idHeader;
         }
         if (amountBill && !billRate) {
            entryErrors.amount = 'amount';
            entryErrors.type = 'bill';
            entryErrors.id = id;
            entryErrors._idHeader = _idHeader;
         }
         if (amountBill && !isValidNumberWithMax(amountBill, 4)) {
            entryErrors.amount = 'amount';
            entryErrors.type = 'bill';
            entryErrors.id = id;
            entryErrors._idHeader = _idHeader;
         }
         if (hoursBill && !isValidNumberWithMax(hoursBill, 3)) {
            entryErrors.hours = 'hours';
            entryErrors.type = 'bill';
            entryErrors.id = id;
            entryErrors._idHeader = _idHeader;
         }
         if (billRate && !isValidNumberWithMax(billRate, 3)) {
            entryErrors.hours = 'hours';
            entryErrors.amount = 'amount';
            entryErrors.type = 'bill';
            entryErrors.id = id;
            entryErrors._idHeader = _idHeader;
         }
      }

      // Si hay errores, agrégalos al array
      if (Object.keys(entryErrors).length > 0) {
         errorsArray.push(entryErrors);
      }
   });

   return errorsArray;
};
